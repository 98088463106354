@use "settings" as s;
@use "sass:math";

/**********************************
  news
***********************************/
.c-news {
  &List {
    &__item {
      @include s.media-pc {
        display: flex;
      }
      &:not(:first-child) {
        margin-top: 1.6rem;
      }
    }
    &__inner {
      display: flex;
      align-items: center;
    }
    &__date {
      display: block;
      @include s.fontsize(21, 15);
    }
    &__category {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 8.1rem;
      height: 1.7rem;
      font-size: 1.4rem;
      border: solid .1rem s.$color-black;
      @include s.media-sp {
        margin-left: 1rem;
        margin-top: .5rem;
      }
      @include s.media-pc {
        margin-left: 3.4rem;
      }
    }
    &__title {
      display: block;
      @include s.fontsize(21, 15);
      @include s.media-sp {
        margin-top: .5rem;
      }
      @include s.media-pc {
        margin-left: 2.5rem;
      }
    }
    &__link {
      &:hover {
        text-decoration: underline;
      }
    }
  }
}
