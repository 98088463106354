@use "settings" as s;
@use "sass:math";

/*
  font
----------------------------------*/
// font-size
$fs-min: 10;
$fs-max: 40;
@for $i from $fs-min through $fs-max {
  .fs-#{$i} {
    font-size: #{$i}px !important;
    &-sp {
      @include s.media-sp {
        .fs-sp-#{$i} {
          font-size: #{$i}px !important;
        }
      }
    }
    &-pc {
      @include s.media-pc {
        .fs-pc-#{$i} {
          font-size: #{$i}px !important;
        }
      }
    }
  }
}

// font-weight
.fw-bold {
  font-weight: bold !important;
}

.fw-normal {
  font-weight: 500 !important;
}

// font-feature-settings
.ffs-palt {
  font-feature-settings: 'palt' 1;
}
