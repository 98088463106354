@use "settings" as s;
@use "sass:math";

.l-main {
  width: 100%;
  margin: 0 auto;
  &.--top {
    padding-bottom: 9.6rem;
  }
  &.--page {
    padding-bottom: 12.2rem;
  }
}
