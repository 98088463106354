@use "settings" as s;
@use "sass:math";

/**********************************
  components
***********************************/

@use "heading";
@use "pageTransition";
@use "divider";
@use "card";
@use "block";
@use "table";
@use "header";
@use "kv";
@use "footer";
@use "info";
@use "news";
@use "shop";
