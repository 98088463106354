@use "settings" as s;
@use "sass:math";

.l-container {
  @extend %container;
  @include s.media-sp {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  @include s.media-pc {
    max-width: 100rem + 4rem;
    padding-left: 2rem;
    padding-right: 2rem;
  }
  &-pc {
    @include s.media-pc {
      width: 100%;
      max-width: 100rem + 4rem;
      margin-left: auto;
      margin-right: auto;
      padding-left: 2rem;
      padding-right: 2rem;
    }
  }
  &-sp {
    @include s.media-sp {
    }
  }
}
