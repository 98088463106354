@use "settings" as s;
@use "sass:math";

/**********************************
  pageTransition
***********************************/
.c-pageTransition {
  display: flex;
  justify-content: center;
  &__link {
    display: flex;
    align-items: center;
    font-size: 1.6rem;
    transition-duration: .4s;
    &:hover {
      opacity: .6;
    }
    &::before, &::after {
      display: inline-block;
      content: '|';
    }
    &::before {
      margin-right: 3em;
    }
    &::after {
      margin-left: 3em;
    }
  }
  &__icon {
    &.--cart {
      display: inline-block;
      width: 2.3rem;
      height: 2.3rem;
    }
  }
}
