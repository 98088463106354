@use "settings" as s;
@use "sass:math";

/*
  kv
----------------------------------*/
.c-kv {
  margin-top: s.$headerHeightPC;
  &.--top {
    height: s.vw-pc(546);
    background: #000 url('../images/top/top_kv.jpg') center center/cover no-repeat;
    @include s.media-largeScreen {
      height: 54.6rem;
    }
  }
  &.--page {
    height: 28.9rem;
    margin-bottom: 3.3rem;
    background: #000 url('../images/page/page_kv.jpg') center center/cover no-repeat;
  }
  &Container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
  &Heading {
    &.--top {
      width: 21.4rem;
      &::before {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        display: block;
        width: calc(100vw - 2rem);
        height: s.vw-pc(504);
        content: '';
        border: solid .1rem s.$color-white;
        @include s.media-pc {
          width: calc(100% - 4rem);
        }
        @include s.media-largeScreen {
          max-width: s.$pc-design-width * .1rem;
          height: 50.4rem;
        }
      }
    }
    &__img {
      width: 100%;
    }
  }
}
