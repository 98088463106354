@use "settings" as s;
@use "sass:math";

/**********************************
  shop
***********************************/
.c-shop {
  &List {
    display: flex;
    flex-wrap: wrap;
    &__title {
      width: 5em;
      @include s.fontsize(28, 14);
      font-weight: normal;
    }
    &__content {
      width: calc(100% - 5em);
      @include s.fontsize(28, 14);
      a {
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}
