@use "settings" as s;
@use "sass:math";

/**********************************
  footer
***********************************/
.c-footer {
  &Container {
    @include s.media-pc {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    &.--sub {
      @include s.media-sp {
        display: flex;
        flex-direction: column-reverse;
      }
    }
  }
  &Main {
    color: s.$color-white;
    background-color: s.$color-black;
    @include s.media-sp {
      padding-top: 7rem;
      padding-bottom: 3rem;
    }
    @include s.media-pc {
      padding-top: 5rem;
      padding-bottom: 5.8rem;
    }
    a {
      color: inherit;
    }
  }
  &Logo {
    width: 21.4rem;
    @include s.media-sp {
      margin: 0 auto 7rem;
    }
    &__img {
      width: 100%;
    }
  }
  &Nav {
    &__list {
      display: flex;
      gap: 0 1em;
      @include s.media-sp {
        justify-content: center;
        margin-top: 1.5rem;
      }
    }
    &__item {
      @include s.fontsize(17, 12);
      &::before {
        display: inline-block;
        margin-right: 1em;
        content: '|';
      }
      &:last-child {
        &::after {
          @include s.media-sp {
            display: inline-block;
            margin-left: 1em;
            content: '|';
          }
        }
      }
    }
    &__link {
      &:hover {
        text-decoration: underline;
      }
    }
  }
  &Sub {
    @include s.media-sp {
      padding-top: 3rem;
      padding-bottom: 1rem;
    }
    @include s.media-pc {
      padding-top: 1.8rem;
      padding-bottom: 3rem;
    }
  }
  &Credit {
    @include s.media-sp {
      text-align: center;
    }
    &__copyright {
      @include s.fontsize(20, 14);
      @include s.media-sp {
        font-size: 1.2rem;
      }
    }
  }
  &Social {
    &__list {
      display: flex;
      gap: 0 1.7rem;
      @include s.media-sp {
        gap: 0 3rem;
        justify-content: center;
        margin-bottom: 3rem;
      }
    }
    &__icon {
      opacity: .4;
      &.--instagram {
        width: 3.5rem;
        @include s.media-pc {
          width: 2.1rem;
        }
      }
      &.--facebook {
        width: 3.5rem;
        @include s.media-pc {
          width: 2.1rem;
        }
      }
    }
  }
}
