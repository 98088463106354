@use "settings" as s;
@use "sass:math";

/**********************************
  block
***********************************/
.c-block {
  &.col-2 {
    display: flex;
    @include s.media-sp {
      flex-direction: column-reverse;
    }
    @include s.media-pc {
      gap: 0 5rem;
    }
    &.--reverse {
      @include s.media-pc {
        flex-direction: row-reverse;
      }
    }
    .c-block__figure {
      @include s.media-sp {
        width: 100%;
      }
      @include s.media-pc {
        max-width: calc(50% - 2.5rem);
      }
    }
  }
  &__heading {
    margin-bottom: 2.4rem;
    @include s.fontsize(30, 18);
    font-weight: bold;
  }
  &__text {
    @include s.fontsize(28, 14);
    &:not(:first-of-type) {
      margin-top: 1em;
    }
  }
  &__figure {
    @include s.media-sp {
      margin-bottom: 1.5rem;
    }
  }
}
