$sp-design-width: 768;
$pc-design-width: 1000;

// color
$color-white: #ffffff;
$color-black: #000000;
$color-grey: #272727;
$color-grey02: #707070;

// typography
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@100;300;400;500;700;900&display=swap');

$noto-sans: 'Noto Sans JP', -apple-system, BlinkMacSystemFont, "Helvetica Neue", Helvetica, Arial, 'Lucida Grande', "Segoe UI", "ヒラギノ角ゴ ProN", "Hiragino Kaku Gothic ProN", "游ゴシック Medium", "游ゴシック体", "Yu Gothic Medium", YuGothic, Osaka, "Meiryo UI", "メイリオ", Meiryo, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;

$sans-serif: $noto-sans;

// size
$headerHeightPC: 6.4rem;
