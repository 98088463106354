@use "settings" as s;
@use "sass:math";

/*
  spacing
----------------------------------*/
/* width */
.u-w-full {
  width: 100% !important;
  &-sp {
    @include s.media-sp {
      width: 100% !important;
    }
  }
  &-pc {
    @include s.media-pc {
      width: 100% !important;
    }
  }
}

.u-w-half {
  width: 50% !important;
  &-sp {
    @include s.media-sp {
      width: 50% !important;
    }
  }
  &-pc {
    @include s.media-pc {
      width: 50% !important;
    }
  }
}

/* margin */
// コンパイル後（例）
// .u-mt-10 { margin-top: 1rem; }
$m-min: 0;
$m-max: 100;
@for $i from $m-min through $m-max {
  .u-mt-#{$i} {
    margin-top: #{$i * .1}rem !important;
  }
}
@include s.media-sp {
  @for $i from $m-min through $m-max {
    .u-mt-sp-#{$i} {
      margin-top: #{$i * .1}rem !important;
    }
  }
}
@include s.media-pc {
  @for $i from $m-min through $m-max {
    .u-mt-pc-#{$i} {
      margin-top: #{$i * .1}rem !important;
    }
  }
}
@for $i from $m-min through $m-max {
  .u-mb-#{$i} {
    margin-bottom: #{$i * .1}rem !important;
  }
}
@include s.media-sp {
  @for $i from $m-min through $m-max {
    .u-mb-sp-#{$i} {
      margin-bottom: #{$i * .1}rem !important;
    }
  }
}
@include s.media-pc {
  @for $i from $m-min through $m-max {
    .u-mb-pc-#{$i} {
      margin-bottom: #{$i * .1}rem !important;
    }
  }
}

@for $i from $m-min through $m-max {
  .u-my-#{$i} {
    margin-top: #{$i * .1}rem !important;
    margin-bottom: #{$i * .1}rem !important;
  }
}
@include s.media-sp {
  @for $i from $m-min through $m-max {
    .u-my-sp-#{$i} {
      margin-top: #{$i * .1}rem !important;
      margin-bottom: #{$i * .1}rem !important;
    }
  }
}
@include s.media-pc {
  @for $i from $m-min through $m-max {
    .u-my-pc-#{$i} {
      margin-top: #{$i * .1}rem !important;
      margin-bottom: #{$i * .1}rem !important;
    }
  }
}

@include s.media-pc {
  @for $i from $m-min through $m-max {
    .u-mb-pc-#{$i} {
      margin-bottom: #{$i * .1}rem !important;
    }
  }
}

$m-horizontal-min: 0;
$m-horizontal-max: 200;
@for $i from $m-horizontal-min through $m-horizontal-max {
  .u-ml-#{$i} {
    margin-left: #{$i * .1}rem !important;
  }
}
@include s.media-sp {
  @for $i from $m-horizontal-min through $m-horizontal-max {
    .u-ml-sp-#{$i} {
      margin-left: #{$i * .1}rem !important;
    }
  }
}
@include s.media-pc {
  @for $i from $m-horizontal-min through $m-horizontal-max {
    .u-ml-pc-#{$i} {
      margin-left: #{$i * .1}rem !important;
    }
  }
}
@for $i from $m-horizontal-min through $m-horizontal-max {
  .u-mr-#{$i} {
    margin-right: #{$i * .1}rem !important;
  }
}
@include s.media-sp {
  @for $i from $m-horizontal-min through $m-horizontal-max {
    .u-mr-sp-#{$i} {
      margin-right: #{$i * .1}rem !important;
    }
  }
}
@include s.media-pc {
  @for $i from $m-horizontal-min through $m-horizontal-max {
    .u-mr-pc-#{$i} {
      margin-right: #{$i * .1}rem !important;
    }
  }
}
@for $i from $m-horizontal-min through $m-horizontal-max {
  .u-mx-#{$i} {
    margin-left: #{$i * .1}rem !important;
    margin-right: #{$i * .1}rem !important;
  }
}
@include s.media-sp {
  @for $i from $m-horizontal-min through $m-horizontal-max {
    .u-mx-sp-#{$i} {
      margin-left: #{$i * .1}rem !important;
      margin-right: #{$i * .1}rem !important;
    }
  }
}
@include s.media-pc {
  @for $i from $m-horizontal-min through $m-horizontal-max {
    .u-mx-pc-#{$i} {
      margin-left: #{$i * .1}rem !important;
      margin-right: #{$i * .1}rem !important;
    }
  }
}

/* padding */
// コンパイル後（例）
// .u-pt-10 { padding-top: 1rem; }
$p-min: 0;
$p-max: 100;
@for $i from $p-min through $p-max {
  .u-pt-#{$i} {
    padding-top: #{$i * .1}rem !important;
  }
}
@include s.media-sp {
  @for $i from $p-min through $p-max {
    .u-pt-sp-#{$i} {
      padding-top: #{$i * .1}rem !important;
    }
  }
}
@include s.media-pc {
  @for $i from $p-min through $p-max {
    .u-pt-pc-#{$i} {
      padding-top: #{$i * .1}rem !important;
    }
  }
}
@for $i from $p-min through $p-max {
  .u-pb-#{$i} {
    padding-bottom: #{$i * .1}rem !important;
  }
}
@include s.media-sp {
  @for $i from $p-min through $p-max {
    .u-pb-sp-#{$i} {
      padding-bottom: #{$i * .1}rem !important;
    }
  }
}
@include s.media-pc {
  @for $i from $p-min through $p-max {
    .u-pb-pc-#{$i} {
      padding-bottom: #{$i * .1}rem !important;
    }
  }
}
@for $i from $p-min through $p-max {
  .u-py-#{$i} {
    padding-top: #{$i * .1}rem !important;
    padding-bottom: #{$i * .1}rem !important;
  }
}
@include s.media-sp {
  @for $i from $p-min through $p-max {
    .u-py-sp-#{$i} {
      padding-top: #{$i * .1}rem !important;
      padding-bottom: #{$i * .1}rem !important;
    }
  }
}
@include s.media-pc {
  @for $i from $p-min through $p-max {
    .u-py-pc-#{$i} {
      padding-top: #{$i * .1}rem !important;
      padding-bottom: #{$i * .1}rem !important;
    }
  }
}


$p-horizontal-min: 0;
$p-horizontal-max: 200;
@for $i from $p-horizontal-min through $p-horizontal-max {
  .u-pl-#{$i} {
    padding-left: #{$i * .1}rem !important;
  }
}
@include s.media-sp {
  @for $i from $p-horizontal-min through $p-horizontal-max {
    .u-pl-sp-#{$i} {
      padding-left: #{$i * .1}rem !important;
    }
  }
}
@include s.media-pc {
  @for $i from $p-horizontal-min through $p-horizontal-max {
    .u-pl-pc-#{$i} {
      padding-left: #{$i * .1}rem !important;
    }
  }
}
@for $i from $p-horizontal-min through $p-horizontal-max {
  .u-pr-#{$i} {
    padding-right: #{$i * .1}rem !important;
  }
}
@include s.media-sp {
  @for $i from $p-horizontal-min through $p-horizontal-max {
    .u-pr-sp-#{$i} {
      padding-right: #{$i * .1}rem !important;
    }
  }
}
@include s.media-pc {
  @for $i from $p-horizontal-min through $p-horizontal-max {
    .u-pr-pc-#{$i} {
      padding-right: #{$i * .1}rem !important;
    }
  }
}
@for $i from $p-horizontal-min through $p-horizontal-max {
  .u-px-#{$i} {
    padding-left: #{$i * .1}rem !important;
    padding-right: #{$i * .1}rem !important;
  }
}
@include s.media-sp {
  @for $i from $p-horizontal-min through $p-horizontal-max {
    .u-px-sp-#{$i} {
      padding-left: #{$i * .1}rem !important;
      padding-right: #{$i * .1}rem !important;
    }
  }
}
@include s.media-pc {
  @for $i from $p-horizontal-min through $p-horizontal-max {
    .u-px-pc-#{$i} {
      padding-left: #{$i * .1}rem !important;
      padding-right: #{$i * .1}rem !important;
    }
  }
}
