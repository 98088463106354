@use "settings" as s;
@use "sass:math";

/*
  global
----------------------------------*/
html {
  width: 100%;
  font-size: 62.5%;
  // font-size:#{(math.div(10,375) * 100) * 1}vw;
  scroll-behavior:smooth;
  // @include s.media-min(769px) {
    // font-size: #{(math.div(10,1080) * 100) * 1}vw
  // }
}
body {
  position: relative;
  width: 100%;
  line-height: 1;
  font-size: 1.4rem;
  font-family: s.$sans-serif;
  color: s.$color-black;
}
img {
  max-width: 100%;
  height: auto;
  vertical-align: top;
  white-space: pre;
  transition-duration: .4s;
}
video {
  max-width: 100%;
  height: auto;
}
a {
  &:hover {
    img {
      opacity: .8;
    }
  }
}
