@use "settings" as s;
@use "sass:math";

/**********************************
  heading
***********************************/
.c-heading {
  &A {
    @include s.media-pc {
      display: flex;
      align-items: center;
    }
    &__en {
      display: block;
      letter-spacing: s.tracking(300);
      line-height: math.div(29, 20);
      font-size: 2rem;
      font-weight: bold;
      @include s.media-pc {
        display: inline-block;
      }
    }
    &__jp {
      display: block;
      font-size: 1.2rem;
      @include s.media-sp {
        margin-top: 1.5rem;
      }
      @include s.media-pc {
        display: inline-block;
        margin-left: 2.9rem;
      }
    }
  }
}
