@use "settings" as s;
@use "sass:math";

/**********************************
  header
***********************************/
.c-header {
  z-index: 100;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: s.$headerHeightPC;
  background-color: s.$color-white;
  &Container {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 2.6rem;
    padding-bottom: 2.3rem;
  }
  &Logo {
    width: 21.4rem;
    &__img {
      width: 100%;
    }
  }
  &Toggle {
    display: block;
    &.--open {
      position: relative;
      width: 1.7rem;
      height: 1.3rem;
    }
    &.--close {
      position: absolute;
      right: 2.4rem;
      top: 2.4rem;
      width: 1.8rem;
      height: 1.8rem;
    }
    &__line {
      position: absolute;
      left: 0;
      display: block;
      height: .1rem;
      &.--open {
        width: 100%;
        background-color: s.$color-black;
        &:nth-of-type(1) {
          top: 0;
        }
        &:nth-of-type(2) {
          top: 50%;
        }
        &:nth-of-type(3) {
          top: 100%;
        }
      }
      &.--close {
        top: 50%;
        width: 2.5rem;
        background-color: s.$color-white;
        &:nth-of-type(1) {
          transform: rotate(45deg);
        }
        &:nth-of-type(2) {
          transform: rotate(-45deg);
        }
      }
    }
  }
  &Nav {
    z-index: 101;
    position: fixed;
    right: 0;
    top: 0;
    width: min(80%, 49.6rem);
    height: 100vh;
    padding: 6.4rem 5.6rem;
    color: s.$color-white;
    background-color: rgba(s.$color-black, .9);
    overflow: scroll;
    transform: translateX(100%);
    transition-duration: .4s;
    a {
      color: inherit;
    }
    &__item {
      font-size: 1.8rem;
      &:not(:first-child) {
        margin-top: 5rem;
      }
    }
    &__link {
      display: flex;
      align-items: center;
      transition-duration: .4s;
      &:hover {
        opacity: .6;
      }
    }
    &__icon {
      display: inline-block;
      &.--cart {
        width: 1.8rem;
        height: 1.5rem;
      }
    }
  }
  &Check {
    display: none;
    &:checked {
      & ~ .c-headerNav {
        transform: translateX(0);
      }
    }
  }
}
