@use "settings" as s;
@use "sass:math";

.u-flex {
  display: flex !important;
  &-sp {
    @include s.media-sp {
      display: flex !important;
    }
  }
  &-pc {
    @include s.media-pc {
      display: flex !important;
    }
  }
}

.u-wrap {
  flex-wrap: wrap !important;
  &-sp {
    @include s.media-sp {
      flex-wrap: wrap !important;
    }
  }
  &-pc {
    @include s.media-pc {
      flex-wrap: wrap !important;
    }
  }
}

.u-direction-colmun {
  flex-direction: column !important;
  &-sp {
    @include s.media-sp {
      flex-direction: column !important;
    }
  }
  &-pc {
    @include s.media-pc {
      flex-direction: column !important;
    }
  }
}

.u-direction-colmun-reverse {
  flex-direction: column-reverse !important;
  &-sp {
    @include s.media-sp {
      flex-direction: column-reverse !important;
    }
  }
  &-pc {
    @include s.media-pc {
      flex-direction: column-reverse !important;
    }
  }
}

.u-direction-row-reverse {
  flex-direction: row-reverse !important;
  &-sp {
    @include s.media-sp {
      flex-direction: row-reverse !important;
    }
  }
  &-pc {
    @include s.media-pc {
      flex-direction: row-reverse !important;
    }
  }
}

.u-justify-center {
  justify-content: center !important;
  &-sp {
    @include s.media-sp {
      justify-content: center !important;
    }
  }
  &-pc {
    @include s.media-pc {
      justify-content: center !important;
    }
  }
}

.u-spaceBetween {
  justify-content: space-between !important;
  &-sp {
    @include s.media-sp {
      justify-content: space-between !important;
    }
  }
  &-pc {
    @include s.media-pc {
      justify-content: space-between !important;
    }
  }
}

.u-justify-end {
  justify-content: flex-end !important;
  &-sp {
    @include s.media-sp {
      justify-content: flex-end !important;
    }
  }
  &-pc {
    @include s.media-pc {
      justify-content: flex-end !important;
    }
  }
}

.u-items-center {
  align-items: center !important;
  &-sp {
    @include s.media-sp {
      align-items: center !important;
    }
  }
  &-pc {
    @include s.media-pc {
      align-items: center !important;
    }
  }
}

.u-items-start {
  align-items: start !important;
  &-sp {
    @include s.media-sp {
      align-items: start !important;
    }
  }
  &-pc {
    @include s.media-pc {
      align-items: start !important;
    }
  }
}

.u-items-end {
  align-items: flex-end !important;
  &-sp {
    @include s.media-sp {
      align-items: flex-end !important;
    }
  }
  &-pc {
    @include s.media-pc {
      align-items: flex-end !important;
    }
  }
}

.u-flex-1 {
  flex: 1;
  &-sp {
    @include s.media-sp {
      flex: 1 !important;
    }
  }
  &-pc {
    @include s.media-pc {
      flex: 1 !important;
    }
  }
}
.u-flex-none {
  flex: none !important;
  &-sp {
    @include s.media-sp {
      flex: none !important;
    }
  }
  &-pc {
    @include s.media-pc {
      flex: none !important;
    }
  }
}

.u-gap {
  $min: 0;
  $max: 20;
  @for $i from $min through $max {
    &-#{$i * 5} {
      @for $j from $min through $max {
        &-#{$j * 5} {
          gap: #{$i * .5}rem #{$j * .5}rem !important;
        }
      }
    }
  }
  &-sp {
    @include s.media-sp {
      @for $i from $min through $max {
        &-#{$i * 5} {
          @for $j from $min through $max {
            &-#{$j * 5} {
              gap: #{$i * .5}rem #{$j * .5}rem !important;
            }
          }
        }
      }
    }
  }
  &-pc {
    @include s.media-pc {
      @for $i from $min through $max {
        &-#{$i * 5} {
          @for $j from $min through $max {
            &-#{$j * 5} {
              gap: #{$i * .5}rem #{$j * .5}rem !important;
            }
          }
        }
      }
    }
  }
}
