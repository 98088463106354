@use "settings" as s;
@use "sass:math";

/**********************************
  card
***********************************/
.c-card {
  @include s.media-sp {
    margin-bottom: 5rem;
  }
  @include s.media-pc {
    width: calc(50% - 2.5rem);
  }
  &__figure {
    width: 100%;
    margin-bottom: 1.2rem;
  }
  &__img {
    width: 100%;
  }
  &__title {
    margin-bottom: 1.5rem;
    @include s.fontsize(26, 18);
    font-weight: bold;
  }
  &__description {
    margin-bottom: 2.7rem;
    @include s.fontsize(28, 14);
  }
  &__nav {
    display: flex;
    justify-content: flex-end;
    margin-top: 1.2rem;
  }
  &__link {
    display: flex;
    align-items: center;
    font-weight: bold;
    &:hover {
      text-decoration: underline;
    }
    &::before {
      display: inline-block;
      width: .6rem;
      height: 1.1rem;
      margin-right: .6rem;
      content: '';
      background: url('../images/common/icon/icon_arrow_right.svg') center center/contain no-repeat;
    }
  }
}
