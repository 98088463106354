// =================== Media Query - Responsive ==================
@mixin min($breakpoint) {
  @media (min-width: #{$breakpoint}) {
    @content;
  }
}

@mixin max($breakpoint) {
  @media (max-width: #{$breakpoint}) {
    @content;
  }
}

@mixin sp() {
  @include max(767px) {
    @content;
  }
}

@mixin tablet() {
  @media (min-width: 768px) and (max-width: 999px) {
    @content;
  }
}

@mixin pc() {
  @include min(768px) {
    @content;
  }
}

@mixin largeScreen() {
  @include min(1000px) {
    @content;
  }
}
