@use "settings" as s;
@use "sass:math";

/**********************************
  info
***********************************/
.c-info {
  padding-top: 4.4rem;
  padding-bottom: 4.5rem;
  &Heading {
    margin-bottom: 1.5rem;
    font-size: 1.8rem;
    font-weight: bold;
  }
  &Content {
    &__heading {
      margin-bottom: 1.5rem - math.div(1.5rem * 1.2 - 1.5rem, 2)  - math.div(1.2rem * 2 - 1.4rem, 2);
      line-height: 1.2;
      font-size: 1.5rem;
      font-weight: bold;
    }
    &__text {
      @include s.fontsize(28, 14);
    }
  }
}
