@use "settings" as s;
@use "sass:math";

/**********************************
  divider
***********************************/
.c-divider {
  position: relative;
  width: 100%;
  height: 1.6rem;
  border-top: solid .1rem s.$color-black;
  &::after {
    position: absolute;
    left: 50%;
    top: -.9rem;
    transform: translateX(-50%);
    display: block;
    width: 1.4rem;
    height: 1.6rem;
    content: '';
    background-image: url('../images/common/icon/chevron_down.svg');
    background-size: contain;
    background-repeat: no-repeat;
  }
}
