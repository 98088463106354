@use "settings" as s;
@use "sass:math";

/**********************************
  table
***********************************/
.c-table {
  border-collapse: separate;
  border-spacing: 0 .1rem;
  width: 100%;
  th, td {
    @include s.fontsize(24, 14);
    border-bottom: solid .1rem s.$color-grey02;
    @include s.media-sp {
      padding: 1rem 2rem;
    }
    @include s.media-pc {
      padding: 2rem 4rem;
    }
  }
  th {
    vertical-align: middle;
    font-weight: normal;
    background-color: rgba(s.$color-grey, .2);
  }
}
